import {environment} from '../../../environments/environment';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {AuthenticationService} from '../../core/services';


@Component({
    selector: 'app-bundle-downloader',
    templateUrl: './bundle-downloader.component.html',
    styleUrls: ['./bundle-downloader.component.scss'],
})
export class BundleDownloaderComponent implements OnInit, OnDestroy {

    private routerSubscription: Subscription;
    private downloadSubscription: Subscription;
    private bundleId: string;

    readonly bundleUrl = (bundleId: string) => `${environment.backendUrl}/invoicestore/app/api/bundles/${bundleId}`;

    constructor(private route: ActivatedRoute,
                private http: HttpClient,
                private authenticationService: AuthenticationService) {
        this.getRouterParams();
    }

    ngOnInit(): void {

        const url = this.bundleUrl(this.bundleId);

        // Step 1: Get the signed URL from the backend
        this.http.get(url, {responseType: 'text'}).subscribe(
            (retrievedUrl: string) => {
                console.log('Signed URL:', retrievedUrl);

                // Step 2: Redirect directly to the signed URL
                window.location.href = retrievedUrl; // Browser handles CORS preflight automatically if needed
            },
            (error) => {
                console.error('Error fetching the signed URL:', error);
            }
        );

        // First: Get the signed URL from the backend
        // this.http.get(this.bundleUrl(this.bundleId), {responseType: 'text'}).subscribe(
        //     (retrievedUrl: string) => {
        //         console.log('Signed URL:', retrievedUrl);
        //
        //         // Second: Preflight request (OPTIONS)
        //         this.http.options(retrievedUrl, {observe: 'response'}).subscribe(
        //             (optionsResponse) => {
        //                 console.log('Preflight successful:', optionsResponse);
        //
        //                 // Third: Redirect to the signed URL
        //                 window.location.href = retrievedUrl; // Browser redirect
        //             },
        //             (optionsError) => {
        //                 console.error('Preflight failed:', optionsError);
        //             }
        //         );
        //     },
        //     (error) => {
        //         console.error('Error fetching the signed URL:', error);
        //     }
        // );
        // do a get to get signedURL string
        // NEED ? DO a preflight OPTIONS to signedURL to get CORS needed header
        // Redirect to signed URL

        // this.downloadSubscription = this.downloadBundleById(this.bundleId).subscribe((response) => {
        //     if (response.type === HttpEventType.Response) {
        //         // tslint:disable-next-line:no-non-null-assertion
        //         const downloadedFile = new Blob([response.body!], {
        //             // tslint:disable-next-line:no-non-null-assertion
        //             type: response.body!.type,
        //         });
        //
        //         // Extract filename from headers, assuming it’s sent in the 'content-disposition' header
        //         const contentDisposition = response.headers.get('content-disposition');
        //         let filename = 'invoices-bundle.zip'; // Default filename
        //
        //         if (contentDisposition) {
        //             const matches = /filename="([^"]*)"/.exec(contentDisposition);
        //             if (matches != null && matches[1]) {
        //                 filename = matches[1];
        //             }
        //         }
        //
        //         const a = document.createElement('a');
        //         a.setAttribute('style', 'display:none;');
        //         document.body.appendChild(a);
        //         a.download = filename;
        //         a.href = URL.createObjectURL(downloadedFile);
        //         a.target = '_blank';
        //         a.click();
        //         document.body.removeChild(a);
        //     }
        // });
    }

    private downloadBundleById(bundleId: string): Observable<HttpResponse<Blob>> {
        const headers = new HttpHeaders()
            .set('Accept', '*/*')
            .set('Authorization', `Bearer ${this.authenticationService.getUserToken()}`);
        return this.http.get(this.bundleUrl(bundleId), {
            headers,
            observe: 'response',  // Ensure response includes headers
            responseType: 'blob'   // Blob type to handle binary data
        });
    }

    private getRouterParams(): void {
        this.routerSubscription = this.route.paramMap.subscribe((params) => {
            this.bundleId = params.get('bundleId');
        });
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
        this.downloadSubscription.unsubscribe();
    }


}